<template>
<div>
  <navbar></navbar>
  <v-container grid-list-xs>

    <v-card>
     <v-card-title>
       Invoices
       <v-spacer></v-spacer>
       <v-text-field
         v-model="search"
         append-icon="search"
         label="Search"
         single-line
         hide-details
       ></v-text-field>
     </v-card-title>
     <v-data-table
       :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
       :headers="headers"
       :items="invoices"
       :search="search"
     >
       <template slot="items" slot-scope="props">
         <tr @click="props.expanded = !props.expanded">
           <td class="">{{ props.item.ts | dateFormat}}</td>
           <td class="" :class="{unpaid : props.item.status!=undefined}" >{{ props.item | status}}</td>
           <td class="">{{ props.item.ref }}</td>
           <td class="">{{props.item.val}} TW$</td>
           <td class="">{{ props.item.comment }}</td>
           <td class=""><v-btn color="primary" :href="'/invoice/'+props.item['.key']">See Details</v-btn> </td>


         </tr>
       </template>
       <v-alert slot="no-results" :value="true" color="error" icon="warning">
         Your search for "{{ search }}" found no results.
       </v-alert>
     </v-data-table>
    </v-card>
  </v-container>
</div>
</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'

export default {
  name: 'Payments',
  data: () => ({
    headers: [
      { text: 'Date', value: 'ts' },
      { text: 'Status', value: 'status' },
      { text: 'Reference', value: 'ref' },
      { text: 'Amount', value: 'Value' },
      { text: 'Comment', value: 'comment' },

        { text: 'See invoice', sortable:false},
    ],

    search: null,
    searched: [],
  }),
  firebase() {
    return {
      invoices: {
        source:firebase.database().ref('invoices').orderByChild("user").equalTo(this.tUser),
      }
    }
  },
  methods: {
    // fxTotal(item){
    //   let fxTotal=Math.ceil(Number(item.total)*Number(item.fx))
    //   return fxTotal
    // }
  },
  filters: {
    status: function (t){
      if (t.paid==true){
        return 'Paid'
      } else {

        return 'Unpaid: due date: '+moment(t.ts).add(1,'week').format("LL")
      }
    },
    dateFormat: function(t) {
      if (t){
        return moment(t).format('ll')
      } else {
        return "Nothing to show"
      }
    },
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
    tUser(){
      if (this.$route.query.id!=undefined){
        return this.$route.query.id
      } else {
        return this.user.id
      }
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.md-field {
    max-width: 300px;
}
.unpaid{
  color:red;
}
</style>
